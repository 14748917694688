import React from 'react';
import {PageColor} from '../components/page-color';

export default class Homepage extends React.Component {
    constructor() {
        super();
        this.state = {url: null};
    }

    componentDidMount() {
        var url = this.props.pageContext.website.homepageVideoDesktop.file.url;
        if (window.innerWidth < 1400) url = this.props.pageContext.website.homepageVideoTablet.file.url;
        if (window.innerHeight > window.innerWidth) url = this.props.pageContext.website.homepageVideoMobile.file.url;
        this.setState({url});
    }

    render = () => (
        <main className="homepage">
            <PageColor color="transparent" nav="white"/>
            <div className="relative h-100">
                <div className="absolute inset-0 flex items-center justify-center">
                    <div className="text-white font text-6xl font-bold leading-normal p-4 hero__title">
                        YOUR SOCIAL MEDIA SOULMATE
                    </div>
                </div>
                {this.state.url && <video muted autoPlay loop playsInline className="hero" src={this.state.url}/>}
            </div>
            <section className="services services--reverse">
                <div className="services__content">
                    <h1 className="service__title">
                        <div className="title-poppins">About</div>
                        <div className="title-ivy">Us</div>
                    </h1>
                    <p className="text-black leading-8 pb-8">
                        Creative brains, practical hands, analyzing heads and loving hearts: welcome to Native Nation.
                        Located in Antwerp, we gather almost 15 experts in one mansion. For years, we’ve been the
                        driving force behind the biggest influencer campaigns in Belgium. Clients as Belfius, Gaia,
                        Danone, Jeep, TUI, PepsiCo and many more knocked on our always-open door, with effective and
                        remarkable campaigns as a result.
                    </p>
                    <a href="/about" className="contactButton p-4 text-white inline uppercase font-bold">← About Us</a>
                </div>
                <div className="services__image">
                    <img src="/images/index/index1.jpeg"/>
                </div>
            </section>
            <section className="services services--homepage--2">
                <div className="services__content">
                    <h1 className="service__title">
                        <div className="title-poppins">Our</div>
                        <div className="title-ivy">services</div>
                    </h1>
                    <p className="text-black leading-8 pb-8">
                        How much we love the saying “Just do it”, is just not something that counts when it comes to
                        digital marketing. We help ambitious brands grow by crafting stories that create impact on
                        social media and beyond. Discover our services and turn your followers into ambassadors.
                    </p>
                    <a href="/services" className="contactButton p-4 text-white inline uppercase font-bold">→ Services</a>
                </div>
                <div className="services__image">
                    <div className="services__image__block"/>
                    <img src="/images/about/about2.jpeg"/>
                </div>
            </section>
            <section className="text-center p-24 block w-100 homepage__call-to-action">
                <p className="text-xl leading-8 pb-12">A personal and hands-on approach is what we offer to brands like you, eager to see themselves grow.
                    Curious to see how far we go? Then hit the contact button, cause together we do it <strong>bigger, better
                    and bolder!</strong>
                </p>
                <a href="/contact" className="contactButton p-4 text-white inline uppercase font-bold">Contact Us</a>
            </section>
            <section className="instaFooter bg-white flex inline justify-between">
                <img src="/images/footer/foto6.jpg" className="h-48 w-48 pr-2"/>
                <img src="/images/footer/foto5.jpg" className="h-48 w-48 pr-2"/>
                <img src="/images/footer/foto7.jpg" className="h-48 w-48 pr-2"/>
                <img src="/images/footer/foto8.jpg" className="h-48 w-48 pr-2"/>
                <img src="/images/footer/foto4.jpg" className="h-48 w-48 pr-2"/>
                <img src="/images/footer/foto3.jpg" className="h-48 w-48 pr-2"/>
                <img src="/images/footer/foto2.jpg" className="h-48 w-48 pr-2"/>
                <img src="/images/footer/foto1.jpg" className="h-48 w-48"/>
            </section>
        </main>
    );
}
